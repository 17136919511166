import { type ProductType } from './product-types';

type ProductCategory = {
  name: string;
  products: ProductType[];
};

const productGroupMap = [
  {
    name: 'wall-art',
    products: [
      'poster',
      'leinwand',
      'hahnemuehle',
      'aludibond',
      'acrylglas',
      'forexplatte',
    ] as ProductType[],
  },
  {
    name: 'wall-art-framed',
    products: [
      'poster-kunststoffrahmen',
      'poster-holzrahmen',
      'poster-alurahmen',
      'leinwand-schattenfuge',
      'hahnemuehle-kunststoffrahmen',
      'hahnemuehle-holzrahmen',
      'hahnemuehle-alurahmen',
      'aludibond-gerahmt',
      'acrylglas-gerahmt',
      'forexplatte-gerahmt',
    ] as ProductType[],
  },
  {
    name: 'apparel',
    products: [
      't-shirt',
      't-shirt-fitted-woman',
      'hoodie',
      'sweatshirt',
    ] as ProductType[],
  },
  {
    name: 'apparel-kid',
    products: [
      't-shirt-kid',
      'hoodie-kid',
      'sweatshirt-kid',
    ] as ProductType[],
  },
  {
    name: 'apparel-baby',
    products: [
      'romper-short-sleeve-baby',
      'romper-long-sleeve-baby',
      't-shirt-baby',
      'sweatshirt-baby',
      'bib-baby',
    ] as ProductType[],
  },
  {
    name: 'case-phone',
    products: [
      'phone-cover-iphone',
      'phone-cover-samsung',
    ] as ProductType[],
  },
  {
    name: 'stickers',
    products: [
      'sticker',
      'sticker-sheet',
    ] as ProductType[],
  },
  {
    name: 'stationery',
    products: [
      'notebook',
      'notebook-spiral',
    ] as ProductType[],
  },
  {
    name: 'home-kitchen',
    products: [
      'bag-tote', 
      'mug-ceramic',
      'mug-enamel',
      'doormat',
      'curtain-shower',
      'towel-tea',
    ] as ProductType[],
  },
  {
    name: 'textiles-home',
    products: [
      'blanket-fleece',
      'cushion-decorative',
    ] as ProductType[],
  },
  {
    name: 'towels',
    products: [
      'towel-bath',
      'towel-beach',
    ] as ProductType[],
  },
  {
    name: 'backlit-films',
    products: [ 'backlitfolie' ] as ProductType[],
  },
  {
    name: 'pvcplanes',
    products: [ 'pvcplane' ] as ProductType[],
  },
  {
    name: 'wallpaper',
    products: [ 'fototapete' ] as ProductType[],
  },
] as const satisfies ProductCategory[];

type ProductCategoryName = (typeof productGroupMap)[number]['name'];

export {
  productGroupMap,
  type ProductCategoryName,
  type ProductCategory,
};
